export const Routes = {
  Home: () => "/",
  SignIn: ({ emailError = false }: { emailError?: boolean } = {}) =>
    `/signin${emailError ? "?emailError=true" : ""}`,
  Onboarding: () => "/onboarding",
  Bereavement: {
    Invites: (): string => Routes.Bereavement.Sub("invites"),
    Faq: (): string => Routes.Bereavement.Sub("faq"),
    Resources: (): string => Routes.Bereavement.Sub("resources"),
    Reporting: (): string => Routes.Bereavement.Sub("reporting"),
    Sub: (name: string): string => {
      return `/bereavement/${name}`;
    },
  },
  EstatePlanning: {
    Faq: (): string => Routes.EstatePlanning.Sub("faq"),
    Invites: (): string => Routes.EstatePlanning.Sub("invites"),
    Resources: (): string => Routes.EstatePlanning.Sub("resources"),
    Sub: (name: string): string => {
      return `/estate-planning/${name}`;
    },
  },
  LoginError: () => "/login-error",
  Profile: () => "/profile",
};

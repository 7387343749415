import { getSession, Session } from "@empathy/common-web-auth";
import { AuthenticationService } from "@empathy/common-web-core";

import { getProviderId as getSSOProviderId } from "./sso/ssoAuthentication";

export const refreshToken = async () => {
  const session = await getSession();

  if (session) {
    await forceSwitchToSSO(session);
    const accessToken = session.accessToken || "";
    AuthenticationService.setAccessToken(accessToken);
    return accessToken;
  }

  throw new Error("No session found");
};

const forceSwitchToSSO = async (session: Session) => {
  const email = session.user?.email;
  if (email) {
    const providerId = await getSSOProviderId(email);
    if (providerId) {
      throw new Error("Force switch to SSO");
    }
  }
};

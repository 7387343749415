import { EmpathyLogo, Icons } from "@empathy/common-web-components";
import clsx from "clsx";

import { UserMenu } from "../UserMenu/UserMenu";
import { useLayoutContext } from "./navigation/LayoutProvider";
import { PortalLogo } from "./PortalLogo";

export enum PageHeaderDataHooks {
  Header = "PageHeader.HeaderElement",
  Menu = "PageHeader.MenuElement",
}

export const PageHeader = ({ withSidebar }: { withSidebar: boolean }) => {
  const { setSidebarOpen, sidebarOpen, isXLScreen } = useLayoutContext();

  return withSidebar ? (
    <div className="fixed h-18 top-0 z-20 bg-white w-full">
      {!isXLScreen && (
        <div
          className={clsx("fixed z-20 left-8 py-[20px] xl:hidden w-fit", {
            hidden: sidebarOpen,
          })}
        >
          <div className="flex gap-6 items-center">
            <button
              className="p-3 rounded-full z-50"
              data-testid={PageHeaderDataHooks.Menu}
              onClick={() => setSidebarOpen(true)}
            >
              <Icons.List className="text-plum" />
            </button>
            <div
              className={
                "flex h-8 w-full select-none flex-row items-center gap-[6px]"
              }
            >
              <div className="flex shrink-0 items-center">
                <EmpathyLogo className="h-6 text-plum-80" />
              </div>
              <span className="leading-[32px] text-[22px] font-normal text-plum-80 mb-1">
                connect
              </span>
            </div>
          </div>
        </div>
      )}
      <div
        data-testid={PageHeaderDataHooks.Header}
        className="fixed z-20 right-0 p-[20px]"
      >
        <UserMenu />
      </div>
    </div>
  ) : (
    <NoSidebarHeader />
  );
};

export const NoSidebarHeader = () => {
  return (
    <div className="fixed top-0 bg-white h-16 w-full z-20">
      <div className="flex">
        <PortalLogo />
        <div data-testid={PageHeaderDataHooks.Header} className="p-[20px]">
          <UserMenu />
        </div>
      </div>
    </div>
  );
};

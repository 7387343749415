import { signIn } from "@empathy/common-web-auth";

import { SsoProviderResponseData } from "../../../pages/api/auth/sso-provider";

// once the sso flow is complete, the user will be redirected to this page.
// sso-finalization page will set the access token and redirect to the home page.
const SSO_FINAL_CALLBACK_PAGE = "/sso-finalization";

export const getProviderId = async (email: string) => {
  const response = await fetch("/api/auth/sso-provider", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });

  if (response.status !== 200) {
    return;
  }

  const { providerId } = (await response.json()) as SsoProviderResponseData;

  return providerId;
};

export const startAuthentication = async (providerId: string) => {
  return signIn(providerId, { callbackUrl: SSO_FINAL_CALLBACK_PAGE });
};

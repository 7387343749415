import { Separator } from "@empathy/common-web-components";
import clsx from "clsx";

import { NavSection } from "./NavSection";
import { useNavigationItems } from "./useNavigationItems";

export const SidebarContent = () => {
  const { navSections } = useNavigationItems();

  const multipleSections = navSections.length > 1;

  return (
    <div
      className={clsx("flex flex-col px-3", multipleSections ? "pt-4" : "pt-3")}
    >
      {navSections.map((section) => (
        <div key={section.title}>
          {section.isAdditionalSection && multipleSections && <Separator />}
          <NavSection
            section={section}
            withHeader={multipleSections}
            isAdditionalSection={section.isAdditionalSection}
          />
        </div>
      ))}
    </div>
  );
};

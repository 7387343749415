import "../styles/globals.css";

import { SessionProvider } from "@empathy/common-web-auth";
import { EmpathyUIProvider } from "@empathy/common-web-components";
import {
  DEFAULT_LOCALE,
  EmpathyProvider,
  ErrorBoundry,
} from "@empathy/common-web-core";
import * as Sentry from "@sentry/nextjs";
import type { AppProps } from "next/app";

import { BlockMobile } from "../components/BlockMobile";
import { AppLayout, NextPageWithLayout } from "../components/layout/AppLayout";
import { refreshToken } from "../lib/auth/refreshToken";
import * as locales from "../locales";
import { AnalyticsProvider } from "../modules/core/analytics";

interface MyAppProps extends AppProps {
  pageProps: any;
  Component: NextPageWithLayout;
}
function App({ Component, pageProps }: MyAppProps) {
  const appLayout = Component.appLayout;
  const screenName = Component.screenName;

  return (
    <BlockMobile>
      <EmpathyProvider
        language={{
          initialLocale: DEFAULT_LOCALE,
          locales,
        }}
        authConfig={{ refreshTokenMethod: refreshToken }}
      >
        <AnalyticsProvider>
          <EmpathyUIProvider>
            <ErrorBoundry onError={(error) => Sentry.captureException(error)}>
              <SessionProvider>
                <AppLayout layout={appLayout} screenName={screenName}>
                  <Component {...pageProps} />
                </AppLayout>
              </SessionProvider>
            </ErrorBoundry>
          </EmpathyUIProvider>
        </AnalyticsProvider>
      </EmpathyProvider>
    </BlockMobile>
  );
}

export default App;

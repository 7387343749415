import { Analytics } from "@empathy/common-web-core";
import { useEffect } from "react";

import { usePartnerSlug } from "../partners/usePartnerSlug";
import { useMyPortalRole } from "./useMyPortalRole";

export type { AnalyticsProperties } from "@empathy/common-web-core/types";

export enum BI_CATEGORIES {
  Portal = "PORTAL",
}

export enum BI_SCREENS {
  Invites = "COMPI.INVITES",
  Onboarding = "ONBOARDING",
  Resources = "RESOURCES",
  Reporting = "REPORTING",
  Faq = "COMPI.FAQ",
  Signin = "SIGNIN",
  NotFound = "NOT_FOUND",
  Profile = "PROFILE",
  LifeFaq = "LIFE.FAQ",
  LifeResources = "LIFE.RESOURCES",
  LifeInvites = "LIFE.INVITES",
}

export enum BI_EVENTS {
  SendPhonelessInvitationClicked = "SendPhonelessInvitation Clicked",
  PhonelessInvitationOpened = "PhonelessInvitation Opened",
  SendInvitesClicked = "send-invites clicked",
  SendInvitesAgainClicked = "send-invites-again Clicked",
  ClearFormClicked = "Clear form Clicked",
  ResendCodeClicked = "Resend-code Clicked",
  ProfileMenuClicked = "Profile-menu Clicked",
  SignedIn = "Signed-in",
  VerificationCodeSubmitted = "Verification-code submitted",
  UserMenuContactUsClicked = "User-menu contact-us clicked",
  UserMenuLogoutClicked = "User-menu logout clicked",
  FaqQuestionOpened = "Faq Question opened",
  FaqQuestionClosed = " Faq Question closed",
  TalkingPointsOpened = "Talking points opened",
  TalkingPointsClosed = "Talking points closed",
  TalkingPointsTabClicked = "Talking points tab clicked",
  TalkingPointsTopicClicked = "Talking points topic clicked",
  ManageProfileClicked = "Manage profile clicked",
  ArticleClicked = "Learning-center article clicked",
  VideoClicked = "Learning-center video clicked",
  ScrollToBottom = "scroll_to_bottom",

  TryLifePlanClicked = "Try-life-plan clicked",
  WatchVideoBannerClicked = "Learning-center banner-video clicked",
  VideoModalClosed = "Learning-center video-modal closed",
}

export const analytics = new Analytics<BI_CATEGORIES, BI_SCREENS>().init();

export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { partnerSlug } = usePartnerSlug();
  const { userId } = useMyPortalRole();

  useEffect(() => {
    if (partnerSlug) {
      if (userId) {
        analytics.setUserProperties({
          partner: partnerSlug,
          userId,
        });
      } else {
        analytics.setUserProperties({
          partner: partnerSlug,
        });
      }
    }
  }, [partnerSlug, userId]);

  return <>{children}</>;
};

import { signOut as nextAuthLogout } from "@empathy/common-web-auth";
import { useAuthenticationContext } from "@empathy/common-web-core";
import { useCallback } from "react";

export const useLogout = () => {
  const { logout: commonLogout } = useAuthenticationContext();

  const logout = useCallback(() => {
    commonLogout();
    void nextAuthLogout({ redirect: false });
  }, [commonLogout]);

  return { logout };
};

export const NYL_GBS_PARTNER_SLUG = "nyl-gbs";
export const NYL_COM_PARTNER_SLUG = "nyl-com";
export const METLIFE_PARTNER_SLUG = "metlife";
export const AMICA_PARTNER_SLUG = "amica";
export const NYL_PARTNER_SLUGS = [
  "nyl-gmad",
  "nyl-com",
  NYL_GBS_PARTNER_SLUG,
  "nyl-aarp",
  "nyl-gbs-bereavement",
  "nyl-team",
];

export const GUARDIAN_PARTNER_SLUG = "guardian";

export const NATIONWIDE_PARTNER_SLUG = "nationwide";

export const SYMETRA_PARTNER_SLUG = "symetra";

export const HARTFORD_PARTNER_SLUG = "thehartford";
export const HARTFORD_CARE_PARTNER_SLUG = "thehartford-care";

export const SECURIAN_PARTNER_SLUG = "securian";

export const METCARES_PARTNER_SLUG = "metcares";

export const GBU_BENE_PARTNER_SLUG = "gbu-bene";

export const PALIG_PARTNER_SLUG = "palig";

export const AFLAC_PARTNER_SLUG = "aflac";
export const AFLAC_EMP_PARTNER_SLUG = "aflac-emp";

export const VOYA_SUPPORT_PARTNER_SLUG = "voya-support";

export const GATEWAY_PARTNER_SLUGS = [
  METLIFE_PARTNER_SLUG,
  AMICA_PARTNER_SLUG,
  ...NYL_PARTNER_SLUGS,
  GUARDIAN_PARTNER_SLUG,
  NATIONWIDE_PARTNER_SLUG,
  SYMETRA_PARTNER_SLUG,
  HARTFORD_PARTNER_SLUG,
  SECURIAN_PARTNER_SLUG,
  METCARES_PARTNER_SLUG,
  GBU_BENE_PARTNER_SLUG,
  PALIG_PARTNER_SLUG,
  AFLAC_PARTNER_SLUG,
];

export const GATEWAY_PLANNING_AHEAD_PARTNER_SLUGS = [
  VOYA_SUPPORT_PARTNER_SLUG,
  AFLAC_EMP_PARTNER_SLUG,
];

export const CLAIMS_PARTNER_SLUGS = [NYL_GBS_PARTNER_SLUG];

export const LIFE_PARTNER_SLUGS = [NYL_COM_PARTNER_SLUG];

export const partnerMap: Record<string, string> = {
  "metlife-9mk32k": "metlife-demo",
  "metlife-2m3bkx": METLIFE_PARTNER_SLUG,
};

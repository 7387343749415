import { useQuery } from "@apollo/client";
import { useAuthenticationContext } from "@empathy/common-web-core";

import { MyPortalRoleDocument } from "../../generated/graphql/schema";

export const useMyPortalRole = () => {
  const { isAuth } = useAuthenticationContext();
  const { loading, error, data } = useQuery(MyPortalRoleDocument, {
    skip: !isAuth,
  });

  return {
    userId: data?.myPortalRole?.id,
    loading,
    authId: data?.myPortalRole?.authId,
    email: data?.myPortalRole?.email,
    lifeTenantId: data?.myPortalRole?.lifeTenantId,
    permissions: data?.myPortalRole?.permissions,
    partner: data?.myPortalRole?.partner,
    organization: data?.myPortalRole?.organization,
    profile: data?.myPortalRole?.profile,
    error,
    roles: data?.myPortalRole?.roles,
  };
};

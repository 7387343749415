/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AuthEventDispatcher } from "@empathy/common-web-core";

const openChat = (message = "") => {
  show();
  (window as any).zE("webWidget", "updateSettings", {
    webWidget: {
      contactForm: {
        fields: [
          {
            id: "description",
            prefill: { "*": message },
          },
        ],
      },
    },
  });
  (window as any).zE("webWidget", "open");
};

export const identify = (
  name?: string,
  email?: string | null,
  phone?: string,
) => {
  (window as any).zE("webWidget", "prefill", {
    name: {
      value: name,
      readOnly: true,
    },
    email: {
      value: email,
      readOnly: true,
    },
    phone: {
      value: phone,
      readOnly: true,
    },
  });
};

export const contactUs = () => {
  openChat();
};

export const scheduleDemo = () => {
  openChat("I would like to set up a call for a demo of the Empathy app");
};

export const orderReferralKit = () => {
  openChat(
    "I would like to order a referral kit to show Empathy to the families I work with",
  );
};

export const hide = () => {
  (window as any).zE("webWidget", "hide");
};

export const show = () => {
  (window as any).zE("webWidget", "show");
};

AuthEventDispatcher.registerListener({
  onLogout: () => {
    (window as any).zE("webWidget", "logout");
  },
});
